
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallError,
  getDatesBetween,
  formatTimeToAMPM,
} from "../../../utils";
import moment from "moment";
import { toast } from "react-hot-toast";
import { addDays } from "date-fns";
import { useParams, useNavigate } from 'react-router-dom';
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";
import TimePicker from "react-time-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import "react-time-picker/dist/TimePicker.css";
import "./styles.css";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import CalenderModal from "./Calendermodal";
import Swal from "sweetalert2";
import {requestData } from "../../../services/api/apiHelpers";

let initDatesValue = {
  selection1: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection1",
  },
  selection2: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection2",
  },
  selection3: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection3",
  },
  selection4: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection4",
  },
  selection5: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection5",
    autoFocus: false,
  },
};

let createErrors = {
  activityDetailsId: "",
  tourDate: "",
  timimg: "",
  cutoffTime: [],
  changePrice: "",
  remeningUser: "",
};

function AddAvailability() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const currentActivityData = activities?.find((item) => item?._id === id);
  const [activity, setactivity] = useState({
    activityDetailsId: currentActivityData.activityDetailsId,
    tourDate: [],
    timing: [],
    changePrice: 0,
    remeningUser: currentActivityData.tourPerson,
    activityDetailsName: currentActivityData.activityTitle,
    activityMaxPerson: currentActivityData.tourPerson,
    frontrowseat:currentActivityData.frontRowSeats
  });
  const [dates, setdates] = useState(initDatesValue);
  const [calmodal, setCalenderModal] = useState(false);
  const [showcalk, setshowcalk] = useState(true)
  const [ allTimeSlot ,setallTimeSlot  ] = useState("")
  const className = showcalk ? 'dnone' : 'block';
  const [times, settimes] = useState([
    {
      time: "",
      cutoffTime: "",
    },
  ]);
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);

  const initDateArr = [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
    },
  ];
  const [dateRangeArr, setDateRangeArr] = useState(initDateArr);
  const [isFirstDateSelect, setIsFirstDateSelect] = useState(false);

  useEffect(() => {
    dispatch(getAllActivities());
    viewAllAvailability();
  }, []); 


  const viewAllAvailability = async () => {
    setisLoading(true)
    try {
        let res = await requestData(
            `/admin/viewAllslot`,
            "POST", { activityDetailsId: currentActivityData.activityDetailsId,}
        );
        if (res.status) {
          
          setallTimeSlot(res.data)
            setisLoading(false)
        } else {
            setisLoading(true)
        }
    } catch (error) {
        toast.error(error?.message)
    }
};

  const today = new Date();
  today.setHours(0, 0, 0, 0); 

  const handleDateChange = (selectedDates) => {
    setdates((prev) => {
      let update = { ...prev };

      return {
        ...update,
        ...selectedDates,
      };
    });

    setIsFirstDateSelect(true);
    let arr = [];
    arr.push(selectedDates);
    if (isFirstDateSelect) {
      setDateRangeArr((prev) => [...prev, ...Object.values(selectedDates)]);
    } else {
      setDateRangeArr((prev) => [...Object.values(selectedDates)]);
    }
  };

  const allowOnlyPositiveInteger = (event) => {
    event.preventDefault();

    let inputValue = event.target.value.replace(/\D/g, "");

    if (inputValue !== "") {
      inputValue = parseInt(inputValue, 10);

      if (isNaN(inputValue)) {
        inputValue = "";
      }
    }
    setactivity({ ...activity, [event.target.name]: inputValue });
  }; 

  function normalizeTime(time) {
    return time.trim().toLowerCase();
  }

  const handleValidation = () => {
    const { activityDetailsId, remeningUser } = activity;

    if (activityDetailsId === "") {
      seterrors({
        ...createErrors,
        activityDetailsId: "Activity is required",
      });

      return false;
    }
    if (dateRangeArr.length === 1) {
      seterrors({
        ...createErrors,
        tourDate: "Plaese select slot start date and end Date !!",
      });
      return false;
    }
    if (
      times.filter((item) => item.time.length > 0 && item.cutoffTime.length > 0)
        .length === 0
    ) {
      seterrors({
        ...createErrors,
        timimg:
          "Atleast 1 activity start time and booking cutoff time is required.",
      });

      return false;
    } 

    const times1 = times?.map(item => normalizeTime(item?.time));
    const times2 = allTimeSlot?.map(time => normalizeTime(time)); 
    const hasCommonTime = times1?.some(time1 => times2.includes(time1));
    if (hasCommonTime) {
      seterrors({
        ...createErrors,
        timimg: "This Time slot already Exists",
      });
      toast.error("This time slot Already exist"); 
      return false;
    }

    if (remeningUser === 0) {
      seterrors({
        ...createErrors,
        remeningUser: "Number of remaining seats is required.",
      });

      return false;
    }

    return true;
  };

  const getTourDates = (arr) => {
    let dateArrRange = arr;
    let datesArr = [];
    for (let i = 0; i < dateArrRange.length; i++) {
      let allDates = getDatesBetween(
        dateArrRange[i]?.startDate,
        dateArrRange[i]?.endDate
      );
      allDates.forEach((item) => {
        datesArr.push(item);
      });
    }
    return datesArr;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (activity.remeningUser <= activity.activityMaxPerson) {
        let selectedDateRanges = Object.entries(dates).map((item) => item[1]);
        let datesArr = [];
        for (let i = 0; i < selectedDateRanges.length; i++) {
          let allDates = getDatesBetween(
            selectedDateRanges[i]?.startDate,
            selectedDateRanges[i]?.endDate
          );
          allDates.forEach((item) => {
            datesArr.push(item);
          });
        }

        let bodyData = {
          ...activity,
          tourDate: Array.from(new Set(getTourDates(dateRangeArr))),
          timing: times.filter(
            (item) => item?.time.length > 0 && item?.cutoffTime.length > 0
          ),
        };
        try {
          setisLoading(true);
          let res = await JSON_API["addActivityAvailability"](bodyData);
          if (res.isSuccess) {
            seterrors(createErrors);
            setdates(initDatesValue);
            if (inputRef.current) {
              inputRef.current.value = "";
            }
            settimes([
              {
                time: "",
                cutoffTime: "",
              },
            ]);
            setisLoading(false);
            Swal.fire({
              title: 'Availability added successfully',
              type: 'success',
              text: 'Activity Availability added successfully!',
            })
            navigate("/manage-activity");
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      } else {
        toast.error(
          `Available seats cannot be more than ${activity.activityMaxPerson} for this activity.`
        );
        return;
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  function formatDate(date) {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0'); 
    const day = String(date?.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
  const maxDateAA = new Date(formatDate(new Date(currentActivityData?.endDate)));


  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div
          component="div"
          className="TabsAnimation appear-done enter-done table-hover"
        >
          <div className="main-card mb-3 card ">
            <div className="card-body">
              <div className="card-title">Add Availability For Activity</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-6 d-none">
                  <div className="mb-3">
                    <label
                      htmlFor="activityDetailsId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activities?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                        maxParticipants: item?.tourPerson,
                        startdate: item?.startDate,
                        availableseat: item?.tourPerson,
                      }))}
                      onChange={(val) => {
                        setactivity((prev) => {
                          return {
                            ...prev,
                            activityDetailsId: val?.value,
                            activityDetailsName: val?.label,
                            activityMaxPerson: val?.maxParticipants,
                            startdate: val?.startdate,
                            availableseat: val?.availableseat,
                          };
                        });
                        setCalenderModal(true);
                      }}
                    />
                  </div>
                  {errors.activityDetailsId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityDetailsId}
                    </span>
                  )}
                </div>
              </div>




              <div className="dateselectbutton">

                <label

                  className="form-label"
                  style={{ fontSize: "18px", color: "black" }}
                >
                  Select Slot Starting And End Date {" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "23px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    *
                  </span>
                </label>

                <button type="button" className=" btn btn-primary kms_yu_btn" onClick={() => setCalenderModal(true)} > Select Date  </button>
              </div>
              <div className={className}>
                <DateRangePicker
                  minDate={today}
                  maxDate={maxDateAA}
                  months={2}
                  direction="horizontal"
                  initialFocusedRange={[0, 0]}
                  ranges={dateRangeArr}
                  className="selectdatecal"
                  onChange={(item) => {
                    handleDateChange(item);
                  }}
                />

              </div>
              {errors.tourDate && (
                <span className="errorMessageText text-danger">
                  {errors.tourDate}
                </span>
              )}
              <br />
              <label
                htmlFor=""
                className="form-label"
                style={{
                  marginLeft: "15px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Start Time and Booking Cutoff Time*
              </label>
              <div className="field-add-btn">
                <button
                  className="btn btn-primary mb-3"
                  type="button"
                  onClick={() => {
                    let lastEle = times[times.length - 1];

                    if (lastEle.time.trim() === "") {
                      toast.error("Please add an activity start time");
                      return;
                    }
                    if (lastEle.cutoffTime.trim() === "") {
                      toast.error("Please add an activity booking cutoff time");
                      return;
                    }

                    settimes((prev) => [...prev, { time: "", cutoffTime: "" }]);
                  }}
                >
                  +
                </button>
              </div>

              {times?.map((item, index) => (
                <div
                  className="d-flex"
                  key={index}
                  style={{ alignItems: "center" }}
                >
                  <div className="card_add_more col-md-11">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor={`time_${index}`} className="form-label">
                          Start Time
                        </label>
                        <TimePicker
                          id={`time_${index}`}
                          clockIcon={null}
                          clearIcon={null}
                          className="form-control"
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          value={item.time}
                          format="hh:mm a"
                          openClockOnFocus={false}
                          onChange={(time) => {
                            let formattedTime = formatTimeToAMPM(time);
                            settimes((state) => {
                              state[index].time = formattedTime;
                              return JSON.parse(JSON.stringify(state));
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor={`cutoffTime_${index}`}
                          className="form-label"
                        >
                          Booking Cutoff Time
                        </label>
                        <TimePicker
                          id={`cutoffTime_${index}`}
                          clockIcon={null}
                          clearIcon={null}
                          className="form-control"
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          value={item.cutoffTime}
                          format="hh:mm a"
                          openClockOnFocus={false}
                          onChange={(time) => {
                            let formattedTime = formatTimeToAMPM(time);
                            if (
                              moment(times[index].time, "h:mm A").isBefore(
                                moment(formattedTime, "h:mm A")
                              )
                            ) {
                              toast.error(
                                "Booking cutoff time should not be greater than start time"
                              );
                              return;
                            }

                            settimes((state) => {
                              state[index].cutoffTime = formattedTime;

                              return JSON.parse(JSON.stringify(state));
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {index !== 0 && (
                    <button
                      type="button"
                      className="btn btn-danger ml-auto d-table"
                      onClick={() =>
                        settimes((prev) => {
                          let updated = [...prev];
                          updated.splice(index, 1);
                          return updated;
                        })
                      }
                    >
                      -
                    </button>
                  )}
                </div>
              ))}

              {errors.timimg && (
                <span className="errorMessageText text-danger">
                  {errors.timimg}
                </span>
              )}

              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-6 d-none">
                  <div className="mb-3">
                    <label
                      htmlFor="remeningUser"
                      className="form-label font-weight-bold"
                    >
                      Available Seats*
                    </label>
                    <input
                      name="remeningUser"
                      id="remeningUser"
                      type="number"
                      min={"0"}
                      className="form-control"
                      autoComplete="false"
                      value={activity.remeningUser}
                      onChange={allowOnlyPositiveInteger}

                    />
                  </div>
                  {errors.remeningUser && (
                    <span className="errorMessageText text-danger">
                      {errors.remeningUser}
                    </span>
                  )}
                </div>
              </div>

              <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => { }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {calmodal && (
          <CalenderModal
            closeModal={setCalenderModal}
            today={today}
            maxDate={maxDateAA}
            handleDateChange={(item) => handleDateChange(item)}
            dateRangeArr={dateRangeArr}
            errors={errors}
            cssmodal={setshowcalk}
          />
        )}
      </form>
    </>
  );
}

export default AddAvailability;
