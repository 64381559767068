import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import { JSON_API } from "../services/api/jsonApiCallWithInterceptor";

export const getTokens = () => {
  return {
    Authorization: reactLocalStorage.get("Authorization"),
    userType: reactLocalStorage.get("userType"),
    "Access-Control-Allow-Origin": "*",
  };
};

export const getTypes = (value, body) => {
  if (value.params) {
    if (typeof body == "object") {
      return { params: { id: body.id }, body: body };
    } else {
      return { params: null, body: body };
    }
  } else if (value.query) {
    return { query: body };
  }

  return {};
};

export const apiCallSuccess = (data) => {
  toast.success("Action successful!");
};

export const apiCallError = (errorMsg, setError) => {
  toast.error(errorMsg);
  setError(errorMsg);
};

export const allowOnlyPositiveInteger = (event) => {
  let inputValue = event.target.value.replace(/\D/g, "");

  if (inputValue !== "") {
    inputValue = parseInt(inputValue, 10);

    if (inputValue <= 0 || isNaN(inputValue)) {
      inputValue = "";
    }
  }

  return inputValue;
};

export const capitalizeFirstLetter = (event) => {
  const sentence = event.target.value;
  const words = sentence?.split(" ");
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  const updatedSentence = capitalizedWords.join(" ");

  return updatedSentence;
};

export const capitalizeFirstLetter2 = (text) => {
  const sentence = text;
  const words = sentence?.split(" ");
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  const updatedSentence = capitalizedWords.join(" ");

  return updatedSentence;
};
export function getVideoDuration(videoFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const blob = new Blob([event.target.result], { type: videoFile.type });
      const video = document.createElement("video");
      const url = URL.createObjectURL(blob);

      video.addEventListener("loadedmetadata", function () {
        const duration = video.duration;
        resolve(duration);
      });

      video.src = url;
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(videoFile);
  });
}
export function getFileTypeFromUrl(url) {
  const extension = url.split(".").pop().toLowerCase();
  if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png" ||
    extension === "gif"
  ) {
    return "Image";
  } else if (extension === "pdf") {
    return "PDF";
  } else {
    return "Unknown";
  }
}

let currencies = [];
for (const [key, value] of Object.entries(
  require("../constants/currencies.json")
)) {
  currencies.push(value);
}

export const getCurrency = (cCode, activity) => {
  const matchingCurrencies = currencies.filter((ele) => ele.code === cCode);

  let tempObj = {
    name: matchingCurrencies[0].name,
    abbreviation: matchingCurrencies[0].code,
    symbol: matchingCurrencies[0].symbol,
  };

  // Create a shallow copy of activity to make it extensible
  const newActivity = { ...activity };
  newActivity.currencyName = `${matchingCurrencies[0].name}(${matchingCurrencies[0].code})`;

  // Return both tempObj and the modified activity if needed
  return tempObj;
};

export function capitalizepartyFirstLetter(input) {
  if (typeof input !== "string" || input.length === 0) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export const getparticipentType = (participant, bokunparticipant) => {
  let newdata = [];
  if (typeof (participant?.firstRatePrice) == "number") {
    for (let i = 0; i < bokunparticipant.length; i++) {
      let tempObj = {
        pricingCategoryId: bokunparticipant[i].id,
        pertype: capitalizepartyFirstLetter(bokunparticipant[i].ticketCategory),
        age: `${bokunparticipant[i].minAge}-${bokunparticipant[i].maxAge}`,
        price: participant?.firstRatePrice,
      };
      newdata.push(tempObj);
    }
    return newdata;
  }

  // if(participant.length===bokunparticipant.length){

  for (let i = 0; i < bokunparticipant.length; i++) {
    const tempBokunParticipant = participant?.filter(
      (ele) => ele.pricingCategoryId === bokunparticipant[i].id
    );
    let tempObj = {
      pricingCategoryId: bokunparticipant[i].id,
      pertype: capitalizepartyFirstLetter(bokunparticipant[i].ticketCategory),
      age: `${bokunparticipant[i].minAge}-${bokunparticipant[i].maxAge}`,
      rateId: participant[0]?.rateId ?? [],
      selectedRateId: tempBokunParticipant[0]?.selectedRateId ?? null,
      price:
        tempBokunParticipant[0]?.tieredPrices &&
        tempBokunParticipant[0]?.tieredPrices.length > 0
          ? tempBokunParticipant[0]?.tieredPrices[0]?.amount
          : tempBokunParticipant[0]?.price?.amount,
    };
    newdata.push(tempObj);
  }

  return newdata;
};

export const fetchParticipantTypePrice = async (productId, currency) => {
  const bodyData = { productId: productId, currency: currency.abbreviation };

  let res = await JSON_API["fetchParticipantPrice"](bodyData);

  if (res) {
    const retdatas = res.data?.data?.pricesByDateRange[0]?.rates;
    let temppesenger = [];
    let temparrs = [];

    if (Array.isArray(retdatas) && retdatas.length > 0) {
      for (let i = 0; i < retdatas.length; i++) {
        if (
          Array.isArray(retdatas[i]?.passengers) &&
          retdatas[i]?.passengers.length > 0
        ) {
          for (let j = 0; j < retdatas[i].passengers.length; j++) {
            const passenger = retdatas[i].passengers[j];

            const pricingCategoryId = passenger?.pricingCategoryId;

            if (
              temppesenger.filter(
                (ele) => ele?.pricingCategoryId === pricingCategoryId
              ).length > 0
            ) {
              temppesenger.forEach((ele, id) => {
                if (ele?.pricingCategoryId === pricingCategoryId) {
                  ele?.rateId.push(retdatas[i].rateId);
                }
              });
            } else {
              const tempobj = {
                ...passenger,
                rateId: [retdatas[i].rateId],
                selectedRateId: retdatas[i].rateId,
              };
              temppesenger.push(tempobj);
            }
          }
        } else {
          temparrs.push({
            rateId: retdatas[i].rateId,
            price: retdatas[i]?.price,
          });
        }
      }
    }

    if (res.data?.data?.pricesByDateRange[0]?.rates[0]?.passengers.length > 0)
      //  return( res.data?.data?.pricesByDateRange[0]?.rates[0]?.passengers)
      return temppesenger;
    else {
      let priceObject = {
        firstRatePrice: Number(
          res.data?.data?.pricesByDateRange[0]?.rates[0]?.price.amount
        ),
        rates: temparrs,
      };
      return priceObject;
    }
  } else {
    return [];
  }
};
