export function getOnlyFullDate(dateString) {
    const DATE = new Date(dateString);
    var mm = DATE?.getMonth() > 8 ? DATE?.getMonth() + 1 : '0' + (DATE?.getMonth() + 1);
    var dd = DATE?.getDate() > 9 ? DATE?.getDate() : '0' + DATE?.getDate();
    var yyyy = DATE?.getFullYear();

    var reformattedDate = yyyy + '-' + mm + '-' + dd;
    return reformattedDate;
}

export function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        var mm = currentDate?.getMonth() > 8 ? currentDate?.getMonth() + 1 : '0' + (currentDate?.getMonth() + 1);
        var dd = currentDate?.getDate() > 9 ? currentDate?.getDate() : '0' + currentDate?.getDate();
        var yyyy = currentDate?.getFullYear();

        var reformattedDate = yyyy + '-' + mm + '-' + dd;
        dates.push(reformattedDate);
        currentDate?.setDate(currentDate?.getDate() + 1);
    }

    return dates;
}
export const dateFormat = (date) => {
    const dateObject = new Date(date);
  
    // Format date to YYYY-MM-DD
    const formattedDate = dateObject?.toISOString().split("T")[0];
    return formattedDate;
  };
  

